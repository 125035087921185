import Vue from 'vue'
import Breakpoints from '../vue/breakpoints'
import LazyLoad from "vanilla-lazyload";

// Intiate Vuejs instance
var app = new Vue({
    el: '#app',
    delimiters: ["${","}"],
    components: {
        Breakpoints,
    }
})

// Scripts to fire once dom has loaded
document.addEventListener("DOMContentLoaded", () => {

    // Lazyload
    var lazy = new LazyLoad({
        elements_selector: ".lazy"
    });

    // Accordions
    // If there is an accordion inside an accordion then data-accordion-allow-multiple needs to be used
    const accordionItems = document.querySelectorAll('[data-accordion-item]');

    if (accordionItems.length) {
        accordionItems.forEach(function(accordion) {
            const accordionLink = accordion.firstElementChild;
            bindEvent(accordionLink, 'click', toggleAccordion);
        });
    }

    function toggleAccordion(e) {
        const link = e.target.closest('[data-accordion-link]');
        const parent = link.closest('[data-accordion]');
        const content = parent.querySelectorAll('[data-accordion-content]');
        console.log(content);

        content.forEach(function(content) {
            if (content.previousElementSibling === link) {
                content.classList.toggle('is-active');
                link.classList.toggle('is-active');
                link.setAttribute('aria-expanded', link.getAttribute('aria-expanded') === 'true' ? 'false' : 'true');
            }
            else {
                if(!("accordionAllowMultiple" in content.parentNode.dataset)) {
                    content.classList.remove('is-active');
                    content.previousElementSibling.classList.remove('is-active');
                    content.previousElementSibling.setAttribute('aria-expanded', 'false');
                }
            }
        });
    }

    // Mobile navigation
    const navTriggers = document.querySelectorAll('.js-nav-btn');
    const mobNav = document.querySelector('.mobile');
    const mobNavOverlay = document.querySelector('.mobile-overlay');

    if(navTriggers){
        navTriggers.forEach(function(navTrigger) {
            bindEvent(navTrigger, "click", function() {
                event.preventDefault();
                document.body.classList.toggle('modal-open');
                // navTrigger.classList.toggle('nav-icon--active');
                mobNav.classList.toggle('mobile--active');
                navTrigger.setAttribute('aria-expanded', navTrigger.getAttribute('aria-expanded') === 'true' ? 'false' : 'true');
                // mobNav.setAttribute('aria-hidden', mobNav.getAttribute('aria-hidden') === 'true' ? 'false' : 'true');
            }, false);
            // navTopValues();
            // window.addEventListener("resize", navTopValues);
        });
    }


    // Get top values for fixed positioning below header
    function navTopValues()  {
        const header = document.querySelector('.header');
        const navDistanceToTop = window.pageYOffset + header.getBoundingClientRect().bottom;
        mobNav.style.top = navDistanceToTop+'px';
        mobNavOverlay.style.top = navDistanceToTop+'px';
    }

    function bindEvent(el, eventName, eventHandler) {
        if (el.addEventListener){
            el.addEventListener(eventName, eventHandler, false);
        } else if (el.attachEvent){
            el.attachEvent('on'+eventName, eventHandler);
        }
    }

})
